$andes-typography-namespace: 'andes-typography';
$typography-specs: (
  'body': (
    'xs': (
      'font-size': 0.75,
      'line-height': 0.938,
    ),
    's': (
      'font-size': 0.875,
      'line-height': 1.125,
    ),
    'm': (
      'font-size': 1,
      'line-height': 1.25,
    ),
    'l': (
      'font-size': 1.125,
      'line-height': 1.375,
    ),
  ),
  'title': (
    'xs': (
      'font-size': 1.125,
      'line-height': 1.375,
    ),
    's': (
      'font-size': 1.25,
      'line-height': 1.563,
    ),
    'm': (
      'font-size': 1.5,
      'line-height': 1.875,
    ),
    'l': (
      'font-size': 1.75,
      'line-height': 2.188,
    ),
    'xl': (
      'font-size': 2,
      'line-height': 2.5,
    ),
  ),
);

@each $type, $sizes in $typography-specs {
  @each $size, $values in $sizes {
    .#{$andes-typography-namespace}--type-#{$type}.typography-wrapper--unit-rem {
      &.#{$andes-typography-namespace}--size-#{$size} {
        font-size: map-get($values, 'font-size') + rem;
        line-height: map-get($values, 'line-height') + rem;
      }
    }

    .#{$andes-typography-namespace}--type-#{$type}.typography-wrapper--unit-em {
      &.#{$andes-typography-namespace}--size-#{$size} {
        font-size: map-get($values, 'font-size') + em;
        line-height: map-get($values, 'line-height') + em;
      }
    }
  }
}

.typography-wrapper--has-max-lines {
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
  padding: 0;
}

$andes-theme: mercadoplay;
@import '../../styles/functions';
@import '~@andes/common/index';

.pill-tag {
  .andes-badge__content {
    display: flex;
    align-items: center;
    gap: pxToRem(4);
  }

  .pill-tag-icon {
    width: auto;
  }
}

.pill-tag-border--red {
  border: 1px solid $andes-red-800;
}

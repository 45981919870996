$andes-theme: mercadoplay;

@use '../../../MeliPlayer/MeliPlayer';
@import '../../../../styles';
@import '~@andes/common/index';

.media-card-video-player {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  display: flex;
  height: 100%;
  width: 100%;
  pointer-events: none;
  background-color: transparent !important;
  border-top-left-radius: pxToRem(6);
  border-top-right-radius: pxToRem(6);

  video {
    border-top-left-radius: pxToRem(6);
    border-top-right-radius: pxToRem(6);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.media-card-video-player__audio-control {
  position: absolute;
  border-radius: 50%;
  background-color: rgb(0 0 0 / 55%);
  border: none;
  display: flex;
  align-items: center;
  width: pxToRem(32);
  height: pxToRem(32);
  bottom: pxToRem(20);
  right: pxToRem(16);
  cursor: pointer;
  z-index: 2;
  pointer-events: all;

  &:hover {
    background-color: rgb(0 0 0 / 90%);
  }
}

.media-card-video-player__progress {
  #{--track-background}: rgba($andes-gray-550-solid, 0.7);
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
}

.loading-overlay {
  all: unset;
  position: fixed;
  inset: 0;
  z-index: 999;
  background: rgba(#000, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  animation: none forwards 150ms linear;

  .andes-progress-indicator-circular {
    margin-bottom: 12px;
  }

  .andes-typography {
    color: #fff;
  }
}

.loading-overlay--animate-in {
  animation-name: show-loading-backdrop;
}

.loading-overlay--hide {
  animation-name: hide-loading-backdrop;
}

@keyframes show-loading-backdrop {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes hide-loading-backdrop {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

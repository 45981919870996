$display: 'desktop' !default;

@use '../../../container/ErrorContainer/ErrorContainer';
@use '../../../components/Typography/Typography';
@use '../../../components/ProgressSelector/ProgressSelector';

@import '../../../styles';

.parental-control-desktop {
  max-width: 720px;
  padding: 0 pxToRem(54);
  width: auto;
  margin: 0 auto;

  &__mplay-typography {
    &--title.andes-typography {
      margin: pxToRem(24) 0 pxToRem(16) 0;
    }

    &--body.andes-typography {
      margin-bottom: pxToRem(16);
    }
  }

  &__mplay-breadcrumb {
    margin-top: pxToRem(32);

    .andes-breadcrumb__link {
      font-weight: 600;
      font-size: 14px;
    }

    .andes-breadcrumb__item {
      font-weight: 400;
      font-size: 14px;
    }
  }
}

.feed__item-full {
  grid-column: 1 / -1;

  .andes-carousel-snapped {
    height: 400px;
    margin: -16px 0 16px;
    border-radius: 18px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    transition: box-shadow 150ms ease-in-out;
    box-shadow: (0 1px 2px rgb(0 0 0 / 12%));

    &:hover {
      box-shadow: (0 9px 16px rgb(0 0 0 / 40%));
    }
  }

  .andes-carousel-snapped__container--content {
    margin: 0 !important;
    width: 100% !important;

    .andes-carousel-snapped__control {
      z-index: 1 !important;
    }

    .andes-carousel-snapped__wrapper {
      height: 400px;

      .andes-carousel-snapped__slide {
        height: 400px;
      }
    }
  }

  .andes-carousel-snapped__pagination {
    width: 100%;
    position: absolute;
    bottom: 24px;
  }
}

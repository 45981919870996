$andes-theme: mercadoplay;

@import '~@andes/common/index';
@import '../../../styles';
@import '~@andes/typography/lib/styles/_variables';

$border-radius-suggestions: 6px;

.content-search {
  form {
    &.nav-search {
      display: flex !important;
      align-items: center;
      position: relative;
      left: 0;
      top: 0;
      right: 0;
      height: pxToRem(48);
      padding: 0 2px;

      &.nav-search-with-sugestions {
        input.content-search-input {
          border-bottom-left-radius: $border-radius-suggestions;
          border-bottom-right-radius: $border-radius-suggestions;
        }
      }
    }

    input[type='text'].content-search-input {
      border: pxToRem(1) solid $andes-gray-250;
      border-radius: pxToRem(16);
      box-shadow: none;
      height: pxToRem(32);
      position: relative;
      z-index: 1;
      flex: 1;
      box-sizing: border-box;
      padding: 0 0 0 pxToRem(30);
      color: $andes-text-color-primary;

      &,
      &::placeholder {
        font-family: $typography-font-family;
        font-weight: $typography-body-font-weight;
        font-size: $typography-body-font-size-s;
        line-height: $typography-body-line-height-s;
      }

      &::placeholder {
        color: $andes-text-color-secondary;
      }

      &--outline {
        border: transparent;
        outline: solid $andes-blue-500 pxToRem(2);
      }

      &:focus {
        border-radius: pxToRem(16);
      }
    }
    @media (min-width: $md-breakpoint) {
      width: pxToRem(300);
      transition: width 300ms ease-in-out;

      &:focus-within {
        width: pxToRem(360);
      }

      input[type='text'].content-search-input {
        &:focus {
          border: pxToRem(1) solid $andes-accent-color;
          outline: pxToRem(1) solid $andes-accent-color;
        }
      }
    }
  }

  .content-search-icon {
    position: absolute;
    left: 12px;
    z-index: 2;

    div {
      display: flex;
      align-items: center;
    }

    svg {
      width: 16px;
    }

    path[fill] {
      fill: $andes-gray-550;
    }
  }

  .sb-suggestions {
    top: pxToRem(44) !important;
    border-radius: $border-radius-suggestions;
    box-shadow: 0 6px 16px rgb(0 0 0 / 10%);
    min-width: pxToRem(360);
  }
}

$andes-theme: mercadoplay;
$display: 'mobile' !default;

@use '../ContentSubtitle/mobile/ContentSubtitle';
@use '../ContentTagList/mobile/ContentTagList';
@use './components/MediaCardVideoPlayer/MediaCardVideoPlayer';
@use '../Typography/Typography';
@use '../Button/mobile/Button';
@use '../ProgressIndicator/ProgressIndicator';
@use '../PillTag/PillTag';
@use '../Pill/Pill.scss';
@use './styles' with (
  $display: $display
);

.mediacard__top-left-item + img {
  border-top-left-radius: 8px;
}

@keyframes z-index-animation {
  from {
    z-index: 1;
  }

  to {
    z-index: 999;
  }
}

@keyframes z-index-animation-reverse {
  from {
    z-index: 999;
  }

  to {
    z-index: 1;
  }
}

@keyframes fade-in-animation {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fade-out-animation {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@mixin animate-z-index($duration: 50ms) {
  animation-name: z-index-animation;
  animation-duration: $duration;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
}

@mixin animate-z-index-reverse($duration: 50ms) {
  @include animate-z-index($duration);

  animation-name: z-index-animation-reverse;
}

@mixin animate-fade-in($duration: 50ms) {
  opacity: 0;
  animation-name: fade-in-animation;
  animation-duration: $duration;
  animation-fill-mode: forwards;
}

@mixin animate-fade-out($duration: 50ms) {
  opacity: 1;
  animation-name: fade-out-animation;
  animation-duration: $duration;
  animation-fill-mode: forwards;
}

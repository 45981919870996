$andes-theme: mercadoplay;
@import '~@andes/common/index';

$border-radius-skeleton: 6px;

.skeleton-container {
  position: relative;
  width: 100%;
  min-height: 16px;
  cursor: default;

  &--with-shadow {
    box-shadow: 0 1px 2px rgb(0 0 0 / 12%);
  }

  &--color-dark {
    background-color: $andes-gray-070-solid;
  }

  &--color-light {
    background-color: $andes-gray-040-solid;
  }

  &--color-lighter {
    background-color: $andes-white;
  }

  &--color-darker {
    background: $andes-gray-250;
  }

  &--variant-circle {
    border-radius: 50%;
  }

  &--with-animation {
    overflow: hidden;

    &::after {
      position: absolute;
      inset: 0;
      transform: translateX(-100%);
      background-image: linear-gradient(
        90deg,
        rgba(#fff, 0) 0,
        rgba(#fff, 0.2) 20%,
        rgba(#fff, 0.5) 60%,
        rgba(#fff, 0)
      );
      animation: shimmer 2s infinite;
      content: '';
    }

    @keyframes shimmer {
      100% {
        transform: translateX(100%);
      }
    }
  }

  &--border-radius-s {
    border-radius: 3px;
  }

  &--border-radius-m {
    border-radius: $border-radius-skeleton;
  }
}

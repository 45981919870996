.feed__item--typography-custom {
  display: flex;
  align-items: center;
  gap: 7px;

  .title-custom-xl {
    font-weight: 800 !important;
    font-size: 24px;
    line-height: 40px;
    letter-spacing: -0.03em;
    padding-right: 1px;
    background: linear-gradient(
      0deg,
      rgb(0 0 0 / 90%) 25.02%,
      rgb(0 0 0 / 40%) 84.07%
    );
    background-clip: text;
    color: transparent;
    text-transform: uppercase;
  }

  .title-custom {
    font-weight: 800 !important;
    font-size: 24px;
    line-height: 40px;
    letter-spacing: -0.03em;
    padding-right: 1px;
    background: linear-gradient(
      0deg,
      rgb(0 0 0 / 90%) 25.02%,
      rgb(0 0 0 / 40%) 84.07%
    );
    background-clip: text;
    color: transparent;
    text-transform: uppercase;
  }

  .title-custom-xs {
    font-size: 18px;
    font-weight: 600 !important;
    line-height: 22px;
    text-transform: uppercase;
    color: #0000008c;
    margin: 0 !important;
  }
}

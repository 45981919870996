$andes-theme: mercadoplay;
$display: 'mobile' !default;

@import '~@andes/common/index';
@import '../../styles';

.feed-component {
  position: relative;
  z-index: 1;
  overflow: auto;
  @if $display ==$mobile {
    height: 100vh;
    overflow-y: auto;
  }

  @if $display ==$desktop {
    &.grid {
      .feed__item {
        min-width: 0;
      }

      .infinite-scroll-component {
        display: grid;
        grid-gap: pxToRem(16);
        grid-template-columns: repeat(1, 1fr);
        padding: pxToRem(32);
        margin: pxToRem(-32);
        grid-template-rows: max-content;

        @media only screen and (min-width: $sm-breakpoint) {
          grid-template-columns: repeat(2, 1fr);
        }

        @media only screen and (min-width: $md-breakpoint) {
          grid-template-columns: repeat(3, 1fr);
        }

        @media only screen and (min-width: $xl-breakpoint) {
          grid-template-columns: repeat(4, 1fr);
        }
      }
    }

    .infinite-scroll-component__outerdiv {
      height: 100%;
    }
  }
}

.feed__item:empty {
  display: none;
}

.feed__item {
  & .banner-content {
    border-radius: 0 0 pxToRem(6) pxToRem(6);
    box-shadow: 0 pxToRem(8) pxToRem(16) 0 rgb(0 0 0 / 10%);
    max-height: pxToRem(22);
  }

  &.feed__item--error-feedback {
    margin-top: pxToRem(116);
    grid-column: 1 / -1;

    img {
      width: 163px;
      height: 131.364px;
      aspect-ratio: 1;
    }
  }
}

@if $display ==$mobile {
  .feed__item + .feed__item:not(:empty) {
    margin-top: pxToRem(16);
  }
}

.feed__spinner-container {
  align-items: center;
  display: flex;
  grid-column: 1/-1;
  justify-content: center;
  padding: pxToRem(16) 0 0;
}

div.feed__spinner--gray {
  width: 32px;
  height: 32px;

  .andes-progress-indicator-circular__progress {
    stroke: $andes-gray-250-solid !important;
    stroke-width: 3px;
  }

  @media screen and (min-width: $sm-breakpoint) {
    width: 48px;
    height: 48px;
  }
}

$andes-theme: mercadoplay;

@import '~@andes/common/index';

.meli-player {
  #{--control-color}: $andes-white;
  #{--button-height}: 24px;
  #{--layout-row-gap}: 20px;
  #{--layout-col-gap}: 12px;
  #{--layer-top-padding}: 24px;
  #{--layer-left-padding}: 20px;
  #{--layer-bottom-padding}: 12px;
  #{--layer-right-padding}: 20px;
  overflow: visible;
  position: relative;
  background-color: #000;
  aspect-ratio: 16/9;
  color: var(--control-color);
  border-top-left-radius: 6px;

  video {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
  }
}

.meli-player--fullscreen {
  #{--layer-top-padding}: 32px;
  #{--layer-left-padding}: 32px;
  #{--layer-bottom-padding}: 32px;
  #{--layer-right-padding}: 32px;
}

.meli-player--fullscreen-force {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  height: 100%;
  width: 100%;
  border-radius: 0;
}

.meli-player__layer-manager {
  display: flex;
  justify-content: flex-end;
  z-index: 1;
  cursor: none;
  position: relative;
  height: 100%;
  width: 100%;
}

.meli-player__layer {
  opacity: 0;
  transition: opacity 300ms linear;
  pointer-events: none;
  position: absolute;
  inset: 0;
  z-index: 1;
  display: flex;
  flex-direction: column;
  padding: var(--layer-top-padding) var(--layer-right-padding)
    var(--layer-bottom-padding) var(--layer-left-padding);
  border-top-left-radius: 6px;
}

.meli-player__layer--top-shadow::before,
.meli-player__layer--bottom-shadow::after {
  content: '';
  position: absolute;
  right: 0;
  left: 0;
  z-index: -1;
  height: 200px;
}

.meli-player__layer--top-shadow::before {
  top: 0;
  background: linear-gradient(to bottom, rgb(0 0 0 / 60%) 0%, transparent 60%);
}

.meli-player__layer--bottom-shadow::after {
  bottom: 0;
  background: linear-gradient(to top, rgb(0 0 0 / 60%) 0%, transparent 60%);
}

.meli-player__layer--visible {
  opacity: 1;
  pointer-events: all;
  cursor: default;
}

.meli-player__playback-background {
  position: absolute;
  inset: 0;
  border: none;
  background: none;
  padding: 0;
  margin: 0;
  width: 100%;
}

.meli-player__layout--top {
  width: 100%;
  margin-bottom: auto;
  position: relative;
}

.meli-player__layout--bottom {
  width: 100%;
  margin-top: auto;
  position: relative;
}

.meli-player__layout--row {
  display: flex;
  gap: var(--layout-row-gap);
}

.meli-player__layout--col {
  display: flex;
  flex-direction: column;
  gap: var(--layout-col-gap);
}

.meli-player__layout--filler {
  flex: 1;
}

.meli-player__button {
  border: none;
  background: none;
  padding: 0;
  margin: 0;
  color: inherit;
  height: var(--button-height);
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  font-family: inherit;
  display: inline-flex;
  align-items: center;

  svg {
    height: 100%;
  }
}

.meli-player__button-text {
  margin-left: 10px;
}

.meli-player__progress-label {
  font-size: 16px;
  line-height: 1.25em;
  word-spacing: 1px;
}

.meli-player__progress-label--secondary {
  opacity: 0.7;
}

.meli-player__slider {
  #{--track-height}: 4px;
  #{--track-hover-zone}: 6px;
  #{--track-radius}: 0;
  #{--thumb-size}: 12px;
  #{--thumb-inner-size}: 16px;
  #{--thumb-hover-zone}: 20px;
  #{--drag-transition}: 200ms ease-out;
  #{--track-background}: #464646;
  #{--track-primary-value-background}: $andes-accent-color;
  #{--track-secondary-value-background}: #717171;
  display: flex;
  align-items: center;
  height: var(--track-hover-zone);
}

.meli-player__slider--dragging,
.meli-player__slider:hover {
  &:not(.meli-player__slider--disabled) {
    #{--track-height}: var(--track-hover-zone);
  }
}

.meli-player__slider__track {
  height: var(--track-height);
  transition: height var(--drag-transition);
  border-radius: var(--track-radius);
  background-color: var(--track-background);
  position: relative;
  flex: 1;
  cursor: pointer;
}

.meli-player__slider__progress {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  border-radius: inherit;
}

.meli-player__slider__progress--primary {
  background-color: var(--track-primary-value-background);
}

.meli-player__slider__progress--secondary {
  background-color: var(--track-secondary-value-background);
}

.meli-player__slider__thumb {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: var(--thumb-hover-zone);
  height: var(--thumb-hover-zone);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  &::before,
  &::after {
    content: '';
    position: absolute;
    border-radius: 50%;
    transition: height var(--drag-transition), width var(--drag-transition),
      box-shadow var(--drag-transition);
  }

  &::before {
    background-color: var(--track-primary-value-background);
    width: var(--thumb-size);
    height: var(--thumb-size);
  }

  &::after {
    background-color: $andes-white;
    width: 0;
    height: 0;
  }
}

.meli-player__slider--dragging .meli-player__slider__thumb,
.meli-player__slider__thumb:hover {
  &::before {
    width: var(--thumb-hover-zone);
    height: var(--thumb-hover-zone);
  }

  &::after {
    width: var(--thumb-inner-size);
    height: var(--thumb-inner-size);
    box-shadow: rgb(0 0 0 / 50%) 0 1px 2px;
  }
}

.meli-player__slider--disabled {
  #{--track-hover-zone}: var(--track-height);

  .meli-player__slider__track {
    cursor: default;
  }
}

.meli-player__progress-control {
  .meli-player__progress-label {
    margin-bottom: 7px;
  }
}

.meli-player__live-tag {
  display: flex;
  flex-direction: row;
}

.meli-player__volume-control {
  #{--slider-transition}: 200ms ease-out;
  display: flex;
  align-items: center;

  .meli-player__slider {
    #{--track-background}: $andes-white;
    #{--track-radius}: 10000px;
    margin-left: 0;
    width: 0;
    transition: width var(--slider-transition),
      margin-left var(--slider-transition);
  }

  .meli-player__slider__thumb {
    opacity: 0;
    transition: opacity var(--slider-transition);
  }
}

.meli-player__volume-control--dragging,
.meli-player__volume-control:hover {
  .meli-player__slider {
    margin-left: 10px;
    width: 100px;
  }

  .meli-player__slider__thumb {
    opacity: 1;
  }
}

.meli-player__autoplay-unmute-layer {
  cursor: none;
}

.meli-player__autoplay-unmute-layer.meli-player__autoplay-unmute-layer--animated {
  &::before,
  &::after,
  .meli-player__autoplay-unmute-button,
  .meli-player__autoplay-unmute-button .meli-player__button-text {
    transition-delay: 3000ms;
    transition-duration: 500ms;
    transition-timing-function: linear;
  }

  .meli-player__autoplay-unmute-button {
    left: var(--layer-left-padding);
    transform: translateX(0);
  }

  &::before,
  &::after,
  .meli-player__autoplay-unmute-button .meli-player__button-text {
    opacity: 0;
  }
}

.meli-player__autoplay-unmute-button {
  font-size: 18px;
  position: absolute;
  bottom: var(--layer-bottom-padding);
  left: 50%;
  transform: translateX(-50%);
}

.meli-player__subtitles-layer {
  z-index: 2;
  pointer-events: none;
  transition: transform 200ms ease-out;
}

.meli-player__cue {
  max-width: 400px;
  min-height: 45px;
  margin: 0 auto;
  position: absolute;
  bottom: var(--layer-bottom-padding);
  right: var(--layer-right-padding);
  left: var(--layer-left-padding);
  text-shadow: 0 0 4px #000;
  font-family: 'Open Sans', Arial, Helvetica, sans-serif;
  font-weight: 700;
  font-size: 20px;
  line-height: 1.35em;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.meli-player__subtitles-layer--with-controls {
  transform: translateY(calc((var(--layer-bottom-padding) + 40px) * -1));
}

.meli-player--fullscreen {
  .meli-player__cue {
    max-width: 50%;
    font-size: 1.85vw;
  }
}

.meli-player__loading-layer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.meli-player__loading-layer__text {
  font-size: 16px;
  margin-top: 8px;
}

.meli-player__poster-layer {
  padding: 0;

  img {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    border-top-left-radius: 6px;
  }
}

.meli-player__settings {
  position: absolute;
  inset: 0;
  opacity: 0;
  transition: opacity 150ms linear;
  pointer-events: none;
  cursor: pointer;
  z-index: 3;
}

.meli-player__settings_overlay {
  position: absolute;
  right: var(--layer-right-padding);
  bottom: calc(var(--layer-bottom-padding) + 48px);
  width: 500px;
  height: 234px;
  background-color: $andes-gray-900-solid;
  opacity: 0.9;
  border-radius: 6px;
  box-sizing: border-box;
  cursor: default;
  display: flex;
  flex-direction: column;

  h2 {
    font-size: 18px;
    line-height: 22px;
    font-weight: 600;
    margin: 0 0 17px;
  }
}

.meli-player__settings_overlay--compact {
  top: 0;
  right: 0;
  left: 0;
  width: auto;
  height: auto;
  border-radius: 0;

  .meli-player__settings_scroll::after {
    border-radius: 0;
  }
}

.meli-player__settings_row {
  display: flex;
  gap: 32px;

  > * {
    flex: 1;
    min-width: 0;
  }
}

.meli-player__scroll-container {
  position: relative;
}

.meli-player__scroll-container__area {
  height: 100%;
  overflow: auto;
}

.meli-player__settings_scroll {
  flex: 1;
  min-height: 0;

  &::before,
  &::after {
    content: '';
    opacity: 0;
    transition: opacity 150ms ease-out;
    pointer-events: none;
    position: absolute;
    right: 0;
    left: 0;
    height: 40px;
  }

  &::before {
    top: 0;
    background: linear-gradient(to bottom, #1a1a1a 0%, rgb(26 26 26 / 0%) 100%);
  }

  &::after {
    bottom: 0;
    background: linear-gradient(to top, #1a1a1a 0%, rgb(26 26 26 / 0%) 100%);
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
  }
}

.meli-player__settings_scroll--has-top-scroll::before {
  opacity: 1;
}

.meli-player__settings_scroll--has-bottom-scroll::after {
  opacity: 1;
}

.meli-player__settings_titles {
  padding: 48px 32px 0;
  flex-shrink: 0;
}

.meli-player__settings_tracks {
  padding: 0 32px;

  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    &::after {
      content: '';
      display: block;
      height: 24px;
      width: 100%;
    }
  }
}

.meli-player__settings-close,
.meli-player__settings-track {
  background: none;
  border: none;
  transition: color 150ms ease-out;
  padding: 0;
  cursor: pointer;
}

.meli-player__settings-close {
  color: $andes-bg-primary;
  position: absolute;
  top: 21px;
  right: 28px;
  height: 24px;
  width: 24px;
}

.meli-player__settings-track {
  height: 41px;
  color: $andes-gray-250-solid;
  border-bottom: rgba($andes-white, 0.3) solid 1px;
  width: 100%;
  text-align: left;
  box-sizing: border-box;
  display: flex;
  align-items: center;

  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 8px;
  }

  svg {
    height: 20px;
    margin-left: auto;
  }
}

.meli-player__settings-track:hover,
.meli-player__settings-track--active {
  color: $andes-white;
}

.meli-player__settings-track--active {
  font-weight: 600;
}

.meli-player__settings--visible {
  opacity: 1;
  pointer-events: all;
}

.meli-player__fullscreen-title {
  display: flex;
  align-items: center;
}

.meli-player__fullscreen-title__info {
  display: flex;
  align-items: center;
  gap: $andes-spacing-8;
}

.meli-player__fullscreen-logo {
  display: flex;
  align-items: center;

  img {
    width: 65px;
    height: 65px;
    margin-right: 16px;
    border-radius: 50%;
  }
}

.meli-player__fullscreen-title__button {
  margin-top: 5px;
  margin-right: 16px;

  svg {
    width: 32px;
    height: 32px;
  }
}

.meli-player__fullscreen-title__title {
  font-size: 28px;
  line-height: 35px;
  font-weight: 600;
}

.meli-player__fullscreen-title__secondary-title {
  font-size: 24px;
  line-height: 30px;
  font-weight: 600;
}

.meli-player__error-layer__content {
  position: absolute;
  inset: 0;
  z-index: 2;
  padding: 32px;
  background-color: rgba(#000, 0.8);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.meli-player__error-layer__text {
  font-size: 24px;
  line-height: 30px;
  text-align: center;
  font-weight: 600;
}

.meli-player__error-layer__button {
  margin-top: 16px;
  background: none;
  padding: 0;
  border: none;
  color: #3483fa;
  font-size: 16px;
  line-height: 16px;
  font-weight: 600;
  cursor: pointer;
}

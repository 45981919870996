$andes-theme: mercadoplay;
$display: 'mobile' !default;

@import '~@andes/common/index';
@import '../../styles';

$primary-column-gap: 65px;
$secondary-column-gap: 48px;
$primary-column-separator-gap: ($primary-column-gap - 1px) / 2;
$secondary-column-padding: $secondary-column-gap / 2;

.dialog-box {
  width: 100%;
  background-color: #1a1a1a;

  .andes-modal__scroll {
    color: #fff;
  }

  .section + .section,
  .andes-typography + .section {
    margin-top: 16px;
  }

  .andes-modal__header {
    @if $display ==$mobile {
      padding: 1.875rem
        pxToRem(20px)
        1.5rem; // fix for andes modal in small resolutions webview
    }

    @if $display ==$desktop {
      padding: 1.875rem 2rem 1.5rem; // fix for andes modal in small resolutions
    }

    background-color: $andes-gray-900-solid;

    .andes-modal__header__contents {
      margin-right: 8px;

      .andes-modal__title {
        line-height: 30px;

        @if $display ==$mobile {
          font-size: 20px;
          font-weight: 600px;
          line-height: 25px;
        }
      }
    }

    button {
      svg path {
        fill: #fff;
      }
    }
  }

  .andes-modal__content {
    @if $display ==$mobile {
      padding: 0
        pxToRem(20px)
        pxToRem(20px); // fix for andes modal in small resolutions webview
    }

    @if $display ==$mobile {
      max-width: inherit !important;
    }

    @if $display ==$desktop {
      max-width: 464px !important; // Override inline style
      padding: 0 2rem 2rem; // fix for andes modal in small resolutions
    }

    background-color: $andes-gray-900-solid;
  }
}

.dialog-box-overlay__loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 2rem;

  .andes-progress-indicator-circular--blue
    .andes-progress-indicator-circular__progress {
    stroke: #fff;
  }
}

.dialog-box-overlay__container {
  display: flex;
  flex-direction: column;

  section {
    display: flex;
    align-items: center;
    justify-content: left;
    padding: 11px 0;
    gap: $andes-spacing-8;

    button {
      margin: 0;
      border: 0;
      padding: 0;

      .andes-button__content {
        justify-content: left;
      }
    }

    button:hover,
    button:active {
      background-color: transparent;
    }

    a {
      text-decoration: none;
      margin: 0;
      border: 0;

      .andes-typography--size-s.andes-typography--type-title,
      .andes-typography.andes-typography--type-body.andes-typography--weight-regular {
        font-weight: 100;
      }
    }

    .andes-typography--size-s.andes-typography--type-title {
      font-size: 14px;
      font-weight: 400;
      margin: 0;
      line-height: 18px;
    }

    @if $display ==$desktop {
      border-bottom: 1px solid #ffffff30;
    }

    @if $display ==$mobile {
      border-bottom: 0;
    }
  }
}

$andes-theme: mercadoplay;

@import '~@andes/common/index';
@import '../../styles';

.banner-content,
.banner-tag-content {
  padding: 0;

  &__container {
    display: flex;
    align-items: center;
    padding: 0 pxToRem(16);
    background-size: cover;

    & > p {
      margin: 0;
    }

    img,
    svg {
      width: pxToRem(37);
      height: pxToRem(14);
      margin: 0 pxToRem(4);
    }
  }

  &__text.andes-typography--color-primary {
    color: $andes-white;
  }
}

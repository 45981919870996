$andes-theme: mercadoplay;
$display: 'desktop' !default;

@use '../../../container/ErrorContainer/ErrorContainer';
@use '../../../components/Typography/Typography';

@import '~@andes/common/index';
@import '../../../styles';

.settings-desktop {
  max-width: 720px;

  &__header {
    margin: pxToRem(32) 0 pxToRem(32) 0;

    &--title.andes-typography {
      margin: pxToRem(16) 0 0 0;
    }
  }

  &__breadcrumb {
    margin: 0;

    .andes-breadcrumb__link {
      font-weight: 600;
      font-size: 14px;
    }

    .andes-breadcrumb__item {
      font-weight: 400;
      font-size: 14px;
    }
  }

  .settings-desktop__container {
    .andes-list__item-second-column {
      padding: 44px 24px 36px;
    }
  }
}

.settings-desktop__settings-list {
  border-radius: 6px;
  padding: 17px 0;

  .andes-list__item {
    height: auto;
    position: relative;
    align-items: center;

    .andes-list__item-second-column {
      padding: 0;
      padding-right: 22px;

      .andes-list__item-chevron--top {
        margin-top: 0.6em;
      }
    }
  }

  .andes-list__item + .andes-list__item::after {
    content: '';
    position: absolute;
    pointer-events: none;
    top: 0;
    right: 40px;
    left: 40px;
    border-top: $andes-gray-100 solid 1px;
  }

  .andes-list__item-anchor {
    box-sizing: border-box;
    padding: 15px 40px;

    .andes-list__item-first-column {
      padding: 0;
      margin: 0;
    }
  }

  .andes-list__item-primary {
    line-height: 1;
  }

  .andes-list__item-secondary {
    margin-top: pxToRem(7);
    line-height: 1;
  }

  .andes-list__item-with-secondary .andes-list__item-anchor {
    padding-bottom: 19px;
  }
}

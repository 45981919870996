@import '../../Tag/mobile/Tag';

.content-tag-list {
  display: flex;

  & > :not(:last-child) {
    margin-right: 4px;
  }

  .tag {
    height: 20px;
    display: inline-flex;
  }

  .andes-tag {
    height: auto;
    padding: 3px 6px 2px;
  }

  .andes-tag__label {
    padding: 0;
  }

  // There's no way to define a non-selectable tag
  .andes-tag--selectable:hover {
    background-color: transparent;
  }
}

$andes-theme: mercadoplay;

@import '~@andes/common/index';
@import '~@andes/typography/lib/styles/_variables';

.mplay-player__content-descriptors-layer {
  pointer-events: none;
}

.mplay-player__content-descriptors {
  display: flex;
  align-items: center;

  .andes-typography {
    color: inherit;
    margin: 0;
  }
}

.mplay-player__content-descriptors__tag {
  margin-right: 8px;
  border-radius: 4px;

  .square-tag {
    border-radius: 5px;
    font-size: $typography-title-font-size-xs;
    height: 32px;
    line-height: 22px;
  }

  .andes-tag {
    height: 32px;
    padding: 0 8px;
    font-size: $typography-title-font-size-m;
    line-height: $typography-title-line-height-m;
    border: none;
    letter-spacing: 0.3px;
  }

  .andes-tag__label {
    padding: 0;
  }
}

.mplay-player__content-descriptors__subtitle {
  font-weight: 600;
  letter-spacing: 0.1px;
}

.meli-player--fullscreen {
  .andes-typography.mplay-player__content-descriptors__title {
    font-size: $typography-body-font-size-m;
    line-height: $typography-body-line-height-m;
  }

  .andes-typography.mplay-player__content-descriptors__subtitle {
    font-size: $typography-title-font-size-m;
    line-height: $typography-title-line-height-m;
  }

  .mplay-player__content-descriptors__tag {
    margin-right: 16px;

    .square-tag {
      border-radius: 7px;
      font-size: $typography-title-font-size-l;
      height: 48px;
      line-height: 35px;
    }

    .andes-tag {
      height: 48px;
      padding: 0 10px;
      font-size: $typography-title-font-size-xl;
      line-height: $typography-title-line-height-xl;
    }
  }
}

.mediacard-skeleton {
  width: 90%;
  height: 350px;
}

.mediacard-wrapper-skeleton {
  width: 100%;
  height: 100%;
}

.mediacard-skeleton__header {
  aspect-ratio: 16/9;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.mediacard-skeleton__body {
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
}

.mediacard-skeleton__texts {
  display: flex;
  flex-direction: column;
  gap: 6px;
  width: 66%;
  position: absolute;
  bottom: 0;
  margin-left: 11px;
  margin-bottom: 11px;
}

.mediacard-skeleton__content-tag-list {
  display: flex;
  gap: 4px;
}

.mediacard-compact-skeleton {
  width: 219px;
  height: 124px;
  margin-top: 21px;
  margin-left: 20px;
}

.playback-finished-overlay {
  img,
  .playback-finished-overlay__backdrop {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
  }

  img {
    object-fit: cover;
    object-position: center;
  }

  .playback-finished-overlay__backdrop {
    background-color: rgba(#000, 0.8);
    box-sizing: border-box;
    padding: var(--layer-top-padding) var(--layer-right-padding)
      var(--layer-bottom-padding) var(--layer-left-padding);
  }
}

.playback-finished-overlay__skip-button--full-screen {
  padding-bottom: 88px;
}

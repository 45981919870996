$andes-theme: mercadoplay;

@import '~@andes/common/index';
@import '~@andes/typography/lib/styles/_variables';
@import '../../../../styles';

.mplay-player__overlay {
  aspect-ratio: 16/9;
  background-color: black;
  position: relative;
  border-top-left-radius: 6px;
}

.mplay-player__overlay__components,
.mplay-player__overlay__backdrop {
  position: absolute;
  inset: 0;
}

.mplay-player__overlay__backdrop {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
  border-top-left-radius: 6px;
}

.mplay-player__overlay__components {
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 24px;
  border-top-left-radius: 6px;
}

.mplay-player__overlay__icon svg {
  height: 48px;
  width: auto;
}

.mplay-player-login-user__overlay__icon svg {
  height: 64px;
  width: auto;
}

.mplay-player__overlay__typography.andes-typography.andes-typography--type-title {
  font-size: $typography-title-font-size-s;
  line-height: $typography-title-line-height-s;
  font-weight: 400;
  text-align: center;
  width: 100%;
  max-width: 320px;

  b {
    font-weight: 600;
  }

  @media (min-width: $sm-breakpoint) {
    font-size: $typography-title-font-size-m;
    line-height: $typography-title-line-height-m;
  }
}

.mplay-player-login-user__overlay__typography.andes-typography.andes-typography--type-title {
  font-size: $typography-title-font-size-m;
  line-height: $typography-title-line-height-m;
  font-weight: 600;
  text-align: center;
  width: 100%;
  max-width: 500px;
  margin-top: 16px;

  b {
    font-weight: 600;
  }
}

.mplay-player__overlay__button {
  z-index: 5;
  padding: 0;
  height: auto;
  margin-top: 16px;
  line-height: 1em;
}

.mplay-player__overlay--shadow-light {
  .mplay-player__overlay__components {
    background-color: rgba(#000, 0.6);
  }
}

.mplay-player__overlay--shadow-strong {
  .mplay-player__overlay__components {
    background-color: rgba(#000, 0.8);
  }
}

$andes-theme: mercadoplay;

@import '~@andes/common/index';
@import '../../../../styles';

.info-summary__item {
  display: flex;
}

.info-summary__item-key {
  font-weight: 550 !important;
  color: $andes-gray-550;
}

.info-summary__item-key::after {
  content: ':';
}

.info-summary__item-value {
  @include text-ellipsis;

  display: inline-block;
  margin-left: 8px;
}

$andes-theme: mercadoplay;
$display: 'mobile' !default;

@import '~@andes/common/index';
@import '../../styles';

.tag-selector {
  .andes-tabs__border {
    height: 0;
  }

  .andes-tabs__scroll-tool {
    @if $display == $desktop {
      height: 100%;

      &--fade {
        width: 120px;
        background: linear-gradient(
          90deg,
          rgb(245 245 245 / 0%) 0%,
          rgb(245 245 245) 100%
        ) !important;
      }

      &--controls {
        width: auto;
        column-gap: $andes-spacing-8 !important;
        padding: 0;
        justify-content: flex-end;

        .control-arrow {
          width: $andes-spacing-24;
          height: $andes-spacing-24;
        }
      }
    }
  }

  .andes-tabs__scroll-tool--fade_left {
    display: none;
    @if $display == $desktop {
      height: 32px;
      position: absolute;
      z-index: 2;
      width: 120px;
      background: linear-gradient(
        -90deg,
        rgb(245 245 245 / 0%) 0%,
        rgb(245 245 245) 100%
      ) !important;
    }
  }

  .andes-tabs__wrapper--scrollable {
    width: calc(100% - 70px);

    @if $display == $mobile {
      width: 100%;
    }
  }

  .andes-tabs {
    gap: 8px;

    .andes-tab:hover:not(.andes-tab--disabled).andes-tab--selected {
      color: var(--tag-custom-color-active, $andes-blue-600);
    }
    @if $display == $mobile {
      gap: 6px;
    }
  }
}

.tag-selector__tabs {
  button:first-child {
    margin-left: 0;
  }

  button {
    margin: 0;

    &:hover {
      background-color: rgb(0 0 0 / 4%);
    }

    &:hover:not(.andes-tab--disabled) {
      color: inherit;
    }
  }

  .tag-selector__tag {
    display: flex;
    height: 32px;
    padding: 7px 0;
    align-items: center;
    border-radius: 56px;
    color: var(--tag-custom-color, $andes-text-color-primary);
    border: 1px solid var(--tag-custom-border-color, $andes-text-color-disabled);
    background-color: var(--tag-custom-bg-color);

    .andes-tab__link {
      height: unset;
      font-weight: lighter;
      margin: 0 pxToRem(12);
      @if $display == $mobile {
        font-size: 12px;
        line-height: 15px;
        font-weight: 400;
      }
    }
  }

  .tag-selector__tag--active {
    color: var(--tag-custom-color-active, $andes-blue-600);
    background-color: var(--tag-custom-bg-color-active, $andes-blue-100);
    border: 1px solid var(--tag-custom-border-color-active, $andes-blue-600);

    &:hover:not(.andes-tab--disabled).andes-tab--selected {
      background-color: rgb(65 137 230 / 20%);
    }
  }
}

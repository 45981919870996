$andes-theme: mercadoplay;

@import '~@andes/common/index';
@import '../../styles';

.progress-selector {
  &__container {
    margin-top: 32px;
  }

  &__options {
    width: auto;
    display: flex;
    align-items: stretch;
    position: relative;
  }

  &__option {
    flex-grow: 1;
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin: 24px 0 4px;

    &::after {
      background-color: $andes-gray-250;
      content: '';
      display: block;
      height: 4px;
      left: 50%;
      position: absolute;
      bottom: 20px;
      width: 100%;
      z-index: 0;
      will-change: background-color;
      transition: background-color 300ms ease-out;
    }

    &-title {
      width: 90px;
      text-align: center;
      margin-bottom: 7px;
      flex: 1;
      display: flex;
      align-items: center;
    }

    &--last,
    &--last.progress-selector__option--checked {
      &::after {
        background-color: transparent;
      }
    }

    &--included {
      &::after {
        background-color: $andes-blue-500;
      }

      &.progress-selector__option > .andes-radio {
        .andes-radio-element .andes-radio__background {
          background-color: $andes-blue-500;
        }

        .andes-radio-element
          .andes-radio__background
          .andes-radio__outer-circle {
          border-color: $andes-blue-500;
        }
      }
    }

    &--checked {
      .andes-radio-element {
        .andes-radio__background {
          width: 62.5%;
          height: 62.5%;
          margin-left: -20%;
          margin-top: -20%;
          left: 37.5%;
          top: 37.5%;
        }
      }
    }

    > .andes-radio {
      z-index: 1;
      padding-right: 6px;

      .andes-radio-element .andes-radio__background {
        background-color: $mplay-gray-250;

        .andes-radio__outer-circle {
          border-color: $mplay-gray-250;
        }

        .andes-radio__inner-circle {
          background-color: $mplay-gray-250;
        }
      }

      .andes-radio-element
        .andes-radio__input:checked
        + .andes-radio__background {
        .andes-radio__outer-circle {
          background-color: $andes-white;
          border-color: $andes-blue-500;
          border-width: 2px;
        }

        .andes-radio__inner-circle {
          background-color: $andes-white;
        }
      }
    }
  }

  &__footer {
    display: grid;
    grid-template-columns: repeat(10, 1fr);
    gap: 16px;
  }

  &__subtitle {
    grid-column: 2 / 10;
    margin: 4px 0;
  }

  &__icon {
    width: 18px;
    height: 18px;
    justify-self: flex-end;
    margin: 4px 0;
  }
}

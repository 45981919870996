$andes-theme: mercadoplay;

@import '~@andes/common/index';

@keyframes fade-out-opacity-skip-layer {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.mplay-player__skip-layer {
  padding: 0 var(--layer-right-padding) var(--layer-bottom-padding) 0;
  height: max-content;
  width: fit-content;
  position: relative;
  float: right;
  top: 100%;
  transform: translateY(-100%);
  pointer-events: none;
  opacity: 1;
  transition: transform 200ms ease-out;

  button:only-of-type {
    font-family: $font-family-primary;
    font-weight: $font-weight-semibold;
    background-color: var(--andes-accent-color);
    border-radius: 4px;
    color: var(--control-color);
    width: fit-content;
    height: 24px;
    font-size: $font-size-12;
    border: none;
    cursor: pointer;
    pointer-events: auto;

    .mplay-player__skip-layer:focus {
      box-shadow: none;
      outline: none;
    }
  }
}

.mplay-player__skip-layer--floating {
  transition: transform 200ms ease-out, opacity 500ms linear;
  opacity: 1;
  z-index: 2;
}

.mplay-player__skip-layer--only-floating {
  transition: transform 200ms ease-out, opacity 500ms linear;
  opacity: 1;
  z-index: 2;
}

.mplay-player__skip-layer--only-floating.mplay-player__skip-layer--with-controls {
  animation: fade-out-opacity-skip-layer 200ms linear;
  opacity: 0;
  z-index: 2;
}

.mplay-player__skip-layer--on-controls {
  transition: transform 200ms ease-out, opacity 200ms linear;
  animation-name: fade-out-opacity-skip-layer;
  animation-iteration-count: 1;
  z-index: 2;
}

.mplay-player__skip-layer--on-controls:not(
    .mplay-player__skip-layer--with-controls
  ) {
  animation: fade-out-opacity-skip-layer 500ms linear;
  opacity: 0;
}

.mplay-player__skip-layer--floating,
.mplay-player__skip-layer--on-controls.mplay-player__skip-layer--with-controls {
  opacity: 1;
  z-index: 2;
}

.mplay-player__skip-layer--hidden {
  opacity: 0;
  width: 0;
  padding: 0;
  transition: transform 200ms ease-out, opacity 500ms linear;
  animation-fill-mode: forwards;
  z-index: -9999;
  pointer-events: none;

  button {
    width: 0;
    padding: 0;
    opacity: 0;
  }
}

.mplay-player__skip-layer--with-controls {
  transform: translateY(calc(-100% - (var(--layer-bottom-padding) + 40px)));
}

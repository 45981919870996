$display: 'desktop' !default;

@use '../DialogBox' with (
  $display: $display
);

.andes-modal__overlay--full {
  align-items: center;
}

.dialog-box {
  max-width: 464px !important; // Override inline style
}

@import '../../../../styles';

.mediacard-wrapper {
  height: 100%;
  position: relative;
  display: flex;

  .mediacard {
    flex: 1;
    min-width: 0;
  }

  & .bmpui-ui-placeholder-loading-player {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 6px 6px 0 0;
    background-clip: padding-box;
  }
}

@import './BreakpointsKeepWatching';
@import '../../../../FeedContainerHub/components/Ranking/desktop/Ranking';
@import '../../../../FeedContainerHub/components/Vertical/desktop/vertical';
@import '../../../../../components/MediaCardVertical/desktop/MediaCardVertical.desktop';
@import '../../../../FeedContainerHub/components/MainSlider/desktop/MainSlider';
@import '../../../../../components/FullMediaCard/desktop/FullMediaCard.desktop';

.feed__item-keepwatching {
  box-sizing: content-box;
  width: 100%;
  padding: 0;
  margin: 0;
  min-width: 0;

  .andes-carousel-snapped__container.andes-carousel-snapped__container--arrows-visible {
    width: 100%;
    margin: 0;
  }

  .andes-carousel-snapped {
    width: 100%;
    padding: 16px 0;
    margin-top: -16px;
  }

  .andes-card--default.mediacard-compact {
    max-width: 140px;
    width: 100%;
    transition: transform 0.2s ease-out;
    box-shadow: none;

    a {
      min-width: 100%;
    }

    &:hover {
      transform: scale3d(1.1, 1.1, 1);
      box-shadow: 0 6px 15px rgb(0 0 0 / 20%);
    }
  }

  .andes-carousel-snapped__control {
    transition: opacity 0.2s ease;
    opacity: 0;

    &:focus {
      opacity: 1;
    }
  }

  &:hover {
    .andes-carousel-snapped__control:not(
        .andes-carousel-snapped__control--disabled
      ) {
      opacity: 1;
    }
  }

  .mediacard-compact__body--image img {
    transition: filter 200ms ease;
  }

  .mediacard-compact__body--image:hover img {
    filter: drop-shadow(0 0 5px rgb(0 0 0 / 40%));
  }
}

.feed__item--typography,
.feed__item-keepwatching {
  grid-column: 1 / -1;
}

.feed__item-preloadcards {
  display: flex;
  gap: 12px;

  .mediacard-compact {
    flex: 0 0 calc((100% - 12px * 2) / 3);

    @media (max-width: $xs-breakpoint) {
      &:nth-child(n + 4) {
        display: none;
      }
    }

    @media (min-width: $xs-breakpoint) and (max-width: $sm-breakpoint) {
      flex-basis: calc((100% - 12px * 3) / 4);

      &:nth-child(n + 5) {
        display: none;
      }
    }

    @media (min-width: $sm-breakpoint) and (max-width: $md-breakpoint) {
      flex-basis: calc((100% - 12px * 4) / 5);
      min-width: 110px;

      &:nth-child(n + 6) {
        display: none;
      }
    }

    @media (min-width: $md-breakpoint) and (max-width: $lg-breakpoint) {
      flex-basis: calc((100% - 12px * 5) / 6);

      &:nth-child(n + 7) {
        display: none;
      }
    }

    @media (min-width: $lg-breakpoint) and (max-width: $xl-breakpoint) {
      flex-basis: calc((100% - 12px * 6) / 7);

      &:nth-child(n + 8) {
        display: none;
      }
    }

    @media (min-width: $xl-breakpoint) {
      flex-basis: calc((100% - 12px * 7) / 8);
    }
  }
}

@media (width >= 1246px) {
  .feed__item-keepwatching {
    .andes-carousel-snapped {
      width: 100%;
      padding-left: 11px;
      margin-left: -11px;
    }
  }
}

@media (min-width: $md-breakpoint) and (width <= 1246px) {
  .andes-carousel-snapped__container--content
    .andes-carousel-snapped__control--size-small.andes-carousel-snapped__control--next {
    right: 0;
  }

  .andes-carousel-snapped__container--content
    .andes-carousel-snapped__control--size-small.andes-carousel-snapped__control--previous {
    left: 0;
  }
}

.square-tag {
  align-items: center;
  aspect-ratio: 1/1;
  border-radius: 3px;
  border: 1px solid #fff;
  box-sizing: border-box;
  color: #fff;
  display: flex;
  font-size: 11px;
  justify-content: center;
  letter-spacing: -5%;
  line-height: 13px;
  min-height: 20px;
}

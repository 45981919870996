$andes-theme: mercadoplay;

@import '~@andes/common/index';
@import '~@andes/typography/lib/styles/_variables';

.mplay-player__top-items-layer {
  position: absolute;
  z-index: 3;
  left: 0;
  margin-left: 16px;
  margin-top: 16px;
  cursor: auto;

  &.mplay-player__top-items-layer--with-controls {
    right: 0;
    margin-right: 16px;
    margin-left: 0;
    left: auto;
  }

  .andes-badge--large.andes-badge--accent {
    border-radius: 3px;
    border: 0.5px solid #e5e5e5;
  }
}

$andes-theme: mercadoplay;

@import '~@andes/common/index';
@import '../../styles';

$horizontal-media-card-border-radius: 6px;

.horizontal-media-card {
  display: flex;
  flex-direction: column;
  text-decoration: none;
  transition: background-color 150ms ease-out;

  &:focus-visible,
  &:hover {
    background-color: $andes-bg-secondary;

    .horizontal-media-card__play-icon {
      opacity: 0.7;
    }
  }

  &:focus-visible {
    position: relative;
    z-index: 1;
  }

  &--skeleton {
    border-top: 1px solid $andes-gray-070;
  }
}

.horizontal-media-card__play-icon {
  opacity: 0;
  transition: opacity 150ms ease-out;
  position: absolute;
  width: 32px;
}

.horizontal-media-card__top-left-item.andes-badge.andes-badge--pill {
  align-self: flex-start;
  border-radius: 0;
  border-bottom-right-radius: 8px;
  max-width: 100%;

  .andes-badge__content {
    @include text-ellipsis;
  }
}

.horizontal-media-card__content {
  padding: 16px 20px;
  display: flex;
  align-items: center;

  &--skeleton {
    @extend .horizontal-media-card__content;

    flex: 1;
  }

  &--with-logo {
    height: 96px;
  }
}

.horizontal-media-card__image-container {
  aspect-ratio: 16/9;
  width: 135px;
  flex-shrink: 0;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;

  img {
    width: 100%;
    max-height: 100%;
    border-radius: $horizontal-media-card-border-radius;
  }

  &--skeleton {
    @extend .horizontal-media-card__image-container;

    z-index: 1;
    flex: 1;
    max-width: 135px;
  }

  &--with-logo {
    height: 96px;
  }
}

.horizontal-media-card__image {
  &--with-logo {
    position: absolute;
    top: 0;
  }
}

.horizontal-media-card__logo-container {
  position: absolute;
}

.horizontal-media-card__logo-container--position-right {
  width: $andes-spacing-40;
  height: $andes-spacing-40;
  box-sizing: border-box;
  border-radius: $andes-spacing-4;
  border: $andes-gray-070 solid 1px;
  right: $andes-spacing-8;
  bottom: $andes-spacing-8;
  background-color: $andes-white;
  box-shadow: 0 0 $andes-spacing-4 rgb(255 255 255 / 50%);

  img {
    width: 100%;
    border-radius: $andes-spacing-4;
  }
}

.horizontal-media-card__logo-container--position-bottom {
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 0;
  width: 100%;
  height: pxToRem(24);
  border-radius: 0 0 $horizontal-media-card-border-radius
    $horizontal-media-card-border-radius;

  img {
    width: 100%;
    max-width: 45px;
  }
}

.horizontal-media-card__description {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  min-width: 0;
  margin-left: 16px;
  font-size: 16px;

  &--skeleton {
    @extend .horizontal-media-card__description;

    gap: 6px;
    margin-top: -1px;

    &-text {
      height: 15px;

      &--large {
        height: 20px;
      }
    }
  }
}

.horizontal-media-card__tags {
  margin-top: 8px;
}

.horizontal-media-card--outline-list {
  .horizontal-media-card__image-container--with-logo {
    box-shadow: 4px 4px 0 rgb(0 0 0 / 10%);
    border-radius: $horizontal-media-card-border-radius;
  }

  .horizontal-media-card__image-container--with-logo--right {
    height: auto;
  }
  .left-pill-exclusive {
    height: $andes-spacing-16;
    font-size: 11px;
    .andes-badge__content {
      height: $andes-spacing-4;
      .pill-icon {
        position: relative !important;
        width: $andes-spacing-12 !important;
        height: $andes-spacing-12;
      }
    }
  }
}

@media (min-width: $lg-breakpoint) and (max-width: ($xl-breakpoint - 1)) {
  .horizontal-media-card__image-container {
    width: 100px;
  }

  .horizontal-media-card__image-container {
    &--with-logo {
      height: 70px;
    }
  }

  .horizontal-media-card__logo-container--fix-bottom {
    height: pxToRem(18);
  }

  .horizontal-media-card__logo-container--float-right {
    width: 32px;
    height: 32px;
    right: 4px;
    bottom: 4px;
  }

  .horizontal-media-card__description {
    font-size: 14px;

    span.horizontal-media-card__title {
      font-size: $font-size-16;
      font-weight: $font-weight-semibold;
      line-height: 20px;
    }
  }

  .horizontal-media-card__subtitle {
    .andes-typography {
      font-size: $font-size-12;
      font-weight: $font-weight-regular;
      line-height: 15px;
    }
  }

  .horizontal-media-card__play-icon {
    width: 20px;
  }
}

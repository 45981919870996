$andes-theme: mercadoplay;

@import '~@andes/common/index';
@import '~@andes/common/lib/styles/visually-hidden';
@import './andes-base';
@import './components-base';
@import '../index';

html,
body {
  font-size: $font-size-base;
  line-height: $line-height-base;
  background-color: $andes-bg-secondary;
}

body {
  // navigation-desktop.css de ml-web-navigation está estableciendo min-width: 920px
  min-width: 0;
}

main[role='main'] {
  display: flex;
  min-height: 88vh;
}

header.nav-header {
  z-index: 3;
}

.ui-page {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  padding: 0;
}

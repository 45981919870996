$andes-theme: mercadoplay;
$display: 'mobile' !default;

@import '~@andes/common/index';
@import '../../styles';

.andes-tab:active:not(.andes-tab--disabled),
.andes-tab.andes-tab--selected:active:not(.andes-tab--disabled) {
  color: $andes-blue-500;
  background-color: transparent;
}

.andes-tabs {
  .andes-tab:hover:not(.andes-tab--disabled).andes-tab--selected {
    color: $andes-blue-500;
  }

  .andes-tab:hover:not(.andes-tab--disabled).andes-tab--stretched::after,
  .andes-tab:hover:not(.andes-tab--disabled, .andes-tab--stretched)
    .andes-tab__link::after {
    background-color: $andes-blue-500;
  }

  &__scroll-tool {
    &--controls {
      display: none;
    }
  }

  @media (hover: none) {
    .andes-tab:hover:not(.andes-tab--disabled) {
      color: inherit;
    }
  }

  @if $display == $desktop {
    .andes-tab--full-width .andes-tab__link {
      padding: 1em 0;
      margin: 0;
    }

    &__wrapper {
      &--scrollable {
        width: 100%;
      }

      button {
        margin: 0 1.5em;
        padding: 0;

        &:first-child {
          margin-left: 0;
        }
      }
    }

    &__scroll-tool {
      &--controls {
        background-color: $andes-bg-secondary;
        display: inherit;
      }
    }
  }
}

.tab-selector-skeleton__tab {
  width: 30px;
  height: 20px;
  margin-right: 50px;

  &:first-child {
    margin-left: 30px;
  }
}

.tab-selector-skeleton {
  display: flex;
  flex-direction: row;
  padding: 15px 0 10px 18px;
  border-bottom: $andes-gray-100 solid 1px;
  overflow: hidden;
}

.tab-selector-skeleton__tabs {
  display: flex;
  flex-direction: row;
}

.tab-selector-skeleton__title {
  min-width: 99px;
  max-width: 99px;
  height: 22px;
}

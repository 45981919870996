$andes-theme: mercadoplay;
$display: 'mobile' !default;
$outline-list-height: 4px;

@import '~@andes/common/index';
@import '../../../styles';

.mediacard {
  position: relative;
  display: flex;
  transition: 200ms box-shadow ease-out;

  &.mediacard-webview {
    .mediacard__image-body {
      width: 40px;
    }
  }

  a,
  .mediacard--container {
    text-decoration: none;
    cursor: pointer;
    width: 100%;
  }

  &__header {
    background: $andes-bg-primary;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    aspect-ratio: 16/9;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    overflow: hidden;

    &-content {
      position: absolute;
      inset: 0;
      opacity: 0;
      transition: opacity 300ms ease-out;

      &--visible {
        opacity: 1;
      }

      @if $display ==$desktop {
        position: relative;

        &::after {
          display: block;
          content: '';
          padding-bottom: 56.25%;
        }
      }
    }

    &--with-overlay {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &--with-overlay::before {
      background-color: rgba(#000, 0.8);
      border-radius: 6px 6px 0 0;
      content: '';
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 2;
      pointer-events: none;
      transition: background-color 500ms ease-in;
    }
  }

  &__body {
    padding: 1rem;
    display: flex;
    flex-direction: row;
    position: relative;
    flex: 1;

    @if $display ==$desktop {
      padding: pxToRem(16) pxToRem(16) pxToRem(20) pxToRem(16);
    }
  }

  &__overlay-button {
    background-color: rgb(100 100 100 / 50%);
    color: #fff;
    top: 50%;
    left: 50%;
    z-index: 2;
    font-size: 14px;
    line-height: 32px;
    padding: 0 12px;
    height: 32px;
    border-radius: 5px;

    &:hover {
      background-color: rgb(100 100 100 / 50%);
      color: #fff;
    }
  }

  &__texts {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    min-width: 0;

    & > h4 {
      margin: 0;
    }

    &__title {
      &.andes-typography {
        line-height: 22px;
      }
    }
  }

  &__image {
    position: relative;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    z-index: 2;
    width: 100%;

    &.mediacard__header {
      &--playing {
        @include animate-z-index-reverse($duration: 0ms);
      }

      &--video-player {
        @include animate-z-index($duration: 0ms);

        width: 100%;
      }
    }

    &-container {
      margin-right: 16px;
      display: flex;
      align-items: flex-start;
      flex-shrink: 0;
      border-radius: 6px;

      & > img {
        border-radius: 6px;
        width: 100%;
      }

      @if $display ==$desktop {
        flex-flow: column;
        margin-right: 0;
        max-width: 48px;
        max-height: 48px;
        border-radius: 6px;
        padding-right: 8px;
      }
    }

    &-body {
      width: 40px;
      height: 40px;
    }

    @if $display ==$desktop {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
    }
  }

  &__top-left-item {
    position: absolute;
    z-index: 3;

    .andes-badge--pill {
      display: block;
      box-shadow: 0 2px 4px 0 rgb(0 0 0 / 20%);
    }

    .andes-badge--small {
      padding: 0 6px;
      line-height: 1.35;

      .andes-badge__content {
        line-height: normal;
        padding: 0;
      }
    }

    .andes-badge--large .andes-badge__content {
      padding: 6px 8px;
    }

    .andes-badge--large.andes-badge--accent {
      @if $display ==$desktop {
        border-bottom-right-radius: 16px;

        & > p {
          line-height: 12px;
        }
      }
    }

    @if $display == $desktop {
      line-height: inherit;
    }
  }

  &__top-right-item {
    position: absolute;
    z-index: 3;
    right: 0;
    margin-right: 16px;
    margin-top: 16px;

    .andes-badge--large.andes-badge--accent {
      border-radius: 3px;
      border: 0.5px solid #e5e5e5;
    }
  }

  &__bottom-left-item {
    position: absolute;
    z-index: 3;
    bottom: 0;
    left: 0;
    margin: 0 auto;
    margin-bottom: 8px;
    margin-left: 16px;
  }

  &__tag {
    margin-top: 8px;

    .tag {
      height: 22px;
    }

    .andes-tag__label {
      font-size: 12px;
    }

    &component {
      margin-right: 4px;
    }
  }

  &--outline-list {
    margin-bottom: $outline-list-height;
    box-shadow: 0 0 16px rgb(0 0 0 / 10%);
  }

  &--outline-list::before {
    content: '';
    position: absolute;
    bottom: -$outline-list-height;
    background: $andes-bg-secondary;
    height: $outline-list-height;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    border-top: 1px solid $andes-gray-040;
    width: 98%;
    left: pxToRem(3);
  }

  @if $display ==$desktop {
    margin-top: 0;

    &:hover {
      border-radius: 6px;
      box-shadow: 0 1px 16px rgb(0 0 0 / 20%);
    }

    & > a {
      flex: 1;
      min-width: 0;
      display: flex;
      flex-direction: column;
    }
  }
}

div.mediacard__loyalty {
  &_banner {
    width: 353px;
    height: 22px;
    border-radius: 0 0 0.375rem 0.375rem;

    &_text {
      position: absolute;
      color: $andes-white;
      padding: 0 16px;
      top: 15%;
    }
  }

  &-footer-content {
    margin-top: 4px;
    width: 100%;

    &--banner {
      border-radius: 0 0 6px 6px;
      display: flex;
      align-items: center;
      padding: 3.5px 16px;

      span {
        color: white;
      }

      @if $display ==$desktop {
        padding: 4px 16px;
      }
    }
  }
}

.mediacard__top-right-item__pill-tag {
  width: 62px;
  height: 20px;

  .andes-badge__content {
    width: 60px;
    padding: 3px 4px 2px;
    line-height: 15px;
  }
}

@media (min-width: $lg-breakpoint) and (max-width: ($xl-breakpoint - 1)) {
  .mediacard__texts {
    .mediacard__texts__title {
      font-size: 20px;
    }

    .mediacard__texts__subtitle {
      .content-subtitle__label {
        font-size: 14px;
      }
    }
  }
}

@import '../../components/Button/mobile/Button';

.mplay-error {
  align-items: center;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  display: flex;
  padding: 0 20px;

  h1 {
    text-align: center;
  }
}

$andes-theme: mercadoplay;
$display: 'mobile' !default;

@use '../../components/TabSelector/TabSelector' with (
  $display: $display
);
@use '../../components/TagSelector/TagSelector' with (
  $display: $display
);

@import '~@andes/common/index';
@import '../../styles/platform';

.filters-container {
  .andes-tabs {
    @if $display ==$mobile {
      .andes-tab--first {
        padding-left: $andes-spacing-20;

        .andes-tab__link {
          margin-left: 0;
        }
      }

      .andes-tab--last {
        padding-right: $andes-spacing-20;

        .andes-tab__link {
          margin-right: 0;
        }
      }
    }
  }

  .andes-tabs__wrapper {
    background: $andes-white;
  }
}

.hub-filter__tag-selector {
  margin: 16px 0 0;
}

@use '../../../components/ContentSearch/desktop/ContentSearch';
@import '../../../styles';

.sub-header-desktop {
  display: grid;
  grid-template-columns: max-content max-content 1fr;
  grid-template-rows: repeat(2, 1fr);
  align-items: center;
  height: auto;
  padding: pxToRem(56) 0;

  .sub-header-desktop__brand {
    .sub-header-desktop__title {
      margin: 0;
    }
  }

  &__title {
    grid-column: 1;
    grid-row: 1;
    margin: 0;
    white-space: nowrap;
  }

  &__back-button {
    grid-row: 1;
    grid-column: 1;
    white-space: nowrap;
    padding: 0;
    display: flex;
    justify-content: start;
    line-height: 15px;
    font-size: 12px;
    height: 23px;
    padding-bottom: 8px;

    &:hover {
      background-color: transparent !important;
    }
  }

  &__vertical-divider {
    display: block;
    grid-column: 2;
    grid-row: 1;
    border-left: 1px solid #d9d9d9;
    width: 0;
    margin: 0 pxToRem(16);
    height: pxToRem(20);
    margin-bottom: calc((2rem - 1.25rem) / 2);
    align-self: end;
    @media (min-width: $md-breakpoint) {
      align-self: auto;
    }
  }

  .content-search {
    grid-row: 2;
    grid-column: 1 / span-1;
    width: 100%;
    align-self: center;
  }

  &__icon {
    font-size: larger;
    place-self: center end;
    padding: 0;

    &:link:hover {
      background-color: transparent;
    }

    svg {
      stroke-width: 0;
    }
  }

  @media (min-width: $md-breakpoint) {
    grid-template-columns: max-content pxToRem(34) auto max-content;
    grid-template-rows: 100%;
    height: 35px;
    align-items: end;

    .content-search {
      grid-row: 1;
      grid-column: 3;

      form.nav-search {
        padding-right: 20px;
      }
    }

    &__icon {
      display: block;
      grid-column: 4;
      padding: 0;
      font-size: larger;
    }
  }
}

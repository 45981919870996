$andes-theme: mercadoplay;
$display: 'desktop' !default;

@use '../../../components/Feed/Feed' with (
  $display: $display
);

@use '../../../components/Feed/wrappers/MediaCardWrapper/MediaCardWrapper';
@use '../../../components/DialogBox/desktop/DialogBox.scss' with (
  $display: $display
);
@use '../../../container/FeedContainerHub/desktop/components/CarouselSnapped/CarouselSnapped.scss';
@use '../../../components/MediaCard/MediaCard' with (
  $display: $display
);

@use '../../../components/StickyScrollContainer/StickyScrollContainer';

@use '../../../container/HubFilters/HubFilters' with (
  $display: $display
);
@use '../../../container/ErrorContainer/ErrorContainer';
@use '../../../components/Snackbar/Snackbar';
@use '../../../components/Image/mobile/Image';
@use '../../../components/SubHeader/desktop/style';
@use '../../../components/FeedContentFinished/FeedContentFinished' with (
  $display: $display
);
@use '../../../components/LoadingOverlay/LoadingOverlay';
@use '../../../@components/commons/ParentalControlModal/ParentalControlModal.scss';
@import '~@andes/common/index';
@import '../../../styles';
@import './../../../@components/commons/CustomTittle/CustomTitle';

.hub-desktop {
  flex: 1;
  height: auto;

  &__header {
    .sub-header-desktop {
      grid-template-columns: max-content 1fr;
      padding-bottom: 0;
    }

    .sub-header-desktop__vertical-divider {
      display: none;
    }

    &--tab-selector {
      margin: pxToRem(16) 0 pxToRem(32) 0;
    }

    &--tab-selector {
      margin: pxToRem(16) 0 0 0;
    }

    @media (min-width: $md-breakpoint) {
      .sub-header-desktop {
        grid-template-columns: max-content pxToRem(34) auto max-content;
      }

      .sub-header-desktop__vertical-divider {
        display: block;
      }
    }
  }

  &__content {
    margin: (2rem - pxToRem(16)) auto pxToRem(24) auto;
    display: flex;
    flex-direction: column;
    height: auto;
    width: auto;
  }

  &__feed {
    height: 100%;
    overflow: initial;

    .infinite-scroll-component {
      margin: 0 !important;
      padding: 0 !important;
      overflow: initial !important;
    }
  }
}

$andes-theme: mercadoplay;

@import '~@andes/common/index';

.progress-indicator {
  background-color: rgba($andes-gray-550-solid, 0.7);

  &__bottom {
    position: absolute;
    bottom: 0;

    &--card {
      border-radius: 0 0 0.375rem 0.375rem;

      & > div.fill {
        border-radius: 0 6px 6px;
      }
    }

    &--video {
      & > div.fill {
        border-radius: 0 6px 6px 0;
      }
    }
  }
}

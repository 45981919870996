$andes-theme: mercadoplay;

@use '../../TabSelector/TabSelector';

@import '~@andes/common/index';
@import '../../../styles';

.season-selector__header {
  display: flex;
  align-items: center;
  padding-left: 1.25rem;
  border-bottom: $andes-gray-100 solid 1px;
}

.season-selector__tabs-container {
  flex: 1;
  min-width: 0;
  margin-left: 22px;

  .andes-tab {
    margin: 0 16px;
  }
}

.season-selector__tabs {
  .andes-tabs__border {
    display: none;
  }

  .andes-tab__link {
    min-width: 48px;
    padding-right: 8px;
    padding-left: 8px;
    margin: 0;
  }

  .andes-tab--left-aligned {
    margin: 0 16px;
  }

  .andes-tabs__scroll-tool--controls {
    background: $andes-white;
  }

  .control-arrow {
    width: 24px;
    height: 24px;
  }

  .control-arrow + .control-arrow {
    margin-left: 6px;
  }

  .andes-tabs__wrapper--scrollable {
    width: calc(100% - 80px);
  }
}

.season-selector__content {
  padding: 20px;

  .andes-card a {
    min-width: 100%;
  }
  @media (min-width: $md-breakpoint) {
    padding: 20px 20px 32px;
  }
}

.season-selector__content--loading {
  display: flex;
  justify-content: center;
}

.season-selector__content--grid {
  display: grid;
  grid-gap: 14px;
  grid-template-columns: repeat(2, 1fr);

  @media (min-width: $sm-breakpoint) {
    grid-template-columns: repeat(3, 1fr);
  }
}

.season-selector__spinner {
  stroke: $andes-gray-250;
}

$andes-theme: mercadoplay;
@use './components/InfoSummary/InfoSummary';
@use '../ContentSubtitle/mobile/ContentSubtitle';
@use '../ContentTagList/mobile/ContentTagList';
@use '../Button/mobile/Button';
@use '../Section/mobile/Section';
@import '../../styles';
@import '~@andes/common/index';
@import '~@andes/message/index';
@import '~@andes/button/index';

.content-detail {
  padding: 1.25rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .andes-typography--size-m.andes-typography--type-body {
    margin-top: 16px;
    margin-bottom: 0;
  }
}

.conten-detail__message {
  padding-bottom: 16px;
  width: 100%;
  .andes-custom-message {
    .andes-message__content {
      .andes-message__text {
        display: flex;
        width: auto;
        @media (min-width: $sm-breakpoint) {
          justify-content: space-between;
          align-items: center;
          width: 100%;
        }
      }
      .nbcu-desktop__login-button {
        height: 32px;
        width: 110px;
        padding: 0;
        font-size: 14px;
        white-space: nowrap;
      }
    }
  }
}
.content-detail__content-subtitle {
  margin-top: 1px;
  width: 100%;
}

.content-detail__content-tag-list {
  margin-top: 8px;

  .andes-tag--selectable:hover {
    background-color: transparent;
  }
}

.content-detail__description,
.content-detail__info-summary {
  margin-top: 16px;
}

.content-detail__description {
  margin-bottom: 0;
}

.content-detail__info-summary {
  padding-bottom: 20px;
}

.content-detail__extra-action.andes-button {
  padding: 0;
  height: auto;
  margin-top: 8px;

  &.andes-button--transparent {
    border: none;
  }

  .andes-button__content {
    line-height: 1.25em;
  }
}

.content-detail__button-container {
  align-self: center;
  padding: 0.625rem;
  width: 100%;
}

.content-detail__button {
  padding: 0.625rem;
  width: 100%;
  height: 3rem;
}

.cp_readonly__button-extra-action.andes-button {
  width: 100%;
  margin-top: 24px;

  @media (min-width: $sm-breakpoint) {
    width: auto;
    margin-top: 16px;
  }
}

.content-detail span.content-detail__secondary-title {
  font-weight: 600;
}

.content-detail__first-section {
  display: flex;
  width: 100%;
}

.content-detail__titles-section {
  min-width: 0;

  .content-detail__title {
    margin: 0;
  }
}

.content-detail__first-section__img {
  border-radius: pxToRem(5);
  width: pxToRem(80);
  height: pxToRem(80);
  margin-right: pxToRem(8);
}

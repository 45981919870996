$andes-theme: mercadoplay;

@import '~@andes/breadcrumb/index';
@import '~@andes/button/index';
@import '~@andes/modal/index';
@import '~@andes/tag/index';
@import '~@andes/progress-indicator-circular/index';
@import '~@andes/progress-indicator-linear/index';
@import '~@andes/list/index';
@import '~@andes/badge/index';
@import '~@andes/card/index';
@import '~@andes/typography/index';
@import '~@andes/slider/index';
@import '~@andes/tabs/index';
@import '~@andes/carousel-snapped/index';
@import '~@andes/carousel-free/index';
@import '~@andes/card/index';
@import '~@andes/radio-button/index';
@import '~@andes/snackbar/index';
@import '~@andes/common/index';
@import '~@andes/technical-error/index';

@mixin root-prop($prop: null, $value: null) {
  @if $prop and $value {
    #{$prop}: $value;
  }
}

:root {
  @include root-prop(--andes-accent-color, #{$andes-accent-color});
}

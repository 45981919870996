$andes-theme: mercadoplay;
@import '~@andes/common/index';

.section {
  display: flex;
  flex-direction: column;

  p,
  h3 {
    margin: 0;
  }

  h3.section {
    &__title {
      &.andes-typography {
        font-weight: 600;
      }
    }
  }

  .section {
    &__content {
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      &.section__content--horizontal {
        gap: 8px;
      }

      > .tag {
        height: 20px;
        display: inline-flex;

        .andes-tag {
          height: auto;
          padding: 2px 6px;
        }

        .andes-tag__label {
          font-size: 12px;
          font-weight: 600;
          padding: 0;
        }

        // There's no way to define a non-selectable tag
        .andes-tag--selectable:hover {
          background-color: transparent;
        }
      }

      &--vertical {
        flex-direction: column;
        align-items: flex-start;

        > * + * {
          margin-top: 10px;
        }
      }
    }
  }
}

.section__title + .section__content {
  margin-top: 3px;
}

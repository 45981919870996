$andes-theme: mercadoplay;

@use '../../../../../components/HorizontalMediaCard/HorizontalMediaCard';

@import '~@andes/common/index';

.vcp-desktop__related-content {
  // La lib del scroll infinito agrega esto como estilo inline pero
  // actualmente no es necesario. El problema es que rompe el uso
  // de position sticky dentro del componente.
  &,
  .infinite-scroll-component {
    overflow: visible !important;
  }

  .feed__item {
    border-bottom: $andes-gray-100 solid 1px;

    .horizontal-media-card {
      .left-pill-exclusive {
        height: $andes-spacing-16;
        font-size: 11px;

        .andes-badge__content {
          height: $andes-spacing-4;
        }
        .pill-icon {
          position: relative !important;
          width: $andes-spacing-12 !important;
          height: $andes-spacing-12;
        }
      }
    }
  }

  .feed__item:first-child {
    border-top-right-radius: 6px;
  }

  .feed__item:last-child {
    border-bottom: none;
  }

  .feed__item + .feed__item {
    margin: 0;
  }

  .feed__item--typography {
    position: sticky;
    top: 0;
    z-index: 1;
    background: #fff;
  }

  &--skeleton {
    padding: 12px 20px;
  }
}

.vcp-desktop__related-content__title {
  padding: 12px 20px;

  &--skeleton {
    height: 22px;
    width: 55%;
  }
}

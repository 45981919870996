$andes-theme: mercadoplay;

@import '~@andes/common/index';
@import '../../ContentTagList/mobile/ContentTagList';
@import '../../../styles';

$primary-column-gap: 65px;
$secondary-column-gap: 48px;
$primary-column-separator-gap: ($primary-column-gap - 1px) / 2;
$secondary-column-padding: $secondary-column-gap / 2;

.content-detail-overlay {
  max-width: 950px !important; // Override inline style
  width: 100%;

  .section + .section,
  .andes-typography + .section {
    margin-top: 16px;
  }

  .andes-modal__header {
    padding: 3em 3em 1.75em; // fix for andes modal in small resolutions
  }

  .andes-modal__content {
    padding: 0 3em 3em; // fix for andes modal in small resolutions
  }
}

.content-detail-overlay__loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 3em;
  min-height: calc(400px - 6em);
}

.content-detail-overlay__columns-container {
  display: flex;
  flex-wrap: wrap;

  .content-detail-overlay__column {
    flex: 1;
    box-sizing: content-box;
  }

  .content-detail-overlay__column--primary {
    flex: 0 0 100%;

    &:not(:last-child) {
      border-bottom: $andes-gray-100 solid 1px;
      padding-bottom: $primary-column-separator-gap;
      margin-bottom: $primary-column-gap - $primary-column-separator-gap - 1px; // Compensates 1px border
    }

    .section__title + .section__content {
      margin-top: 8px;
    }
  }

  .content-detail-overlay__column--secondary:not(:last-child) {
    padding-right: $secondary-column-padding;
  }

  .content-detail-overlay__column--secondary
    + .content-detail-overlay__column--secondary {
    padding-left: $secondary-column-padding;
  }

  @media (min-width: $lg-breakpoint) {
    flex-wrap: nowrap;

    .content-detail-overlay__column--primary {
      flex: 0 1 100%;
    }

    .content-detail-overlay__column--secondary {
      flex: 0 0
        calc((100% - #{$primary-column-gap} - #{$secondary-column-gap}) * 0.25);
    }

    .content-detail-overlay__column--primary:not(:last-child) {
      margin-bottom: 0;
      padding-bottom: 0;
      border-bottom: none;
      border-right: $andes-gray-100 solid 1px;
      padding-right: $primary-column-separator-gap;
      margin-right: $primary-column-gap - $primary-column-separator-gap - 1px; // Compensates 1px border
    }
  }
}

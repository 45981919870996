$andes-theme: mercadoplay;
@import '~@andes/common/index';
@import '../components/SquareTag/SquareTag';

.tag {
  border-radius: 3px;

  .andes-tag {
    border-width: 0;

    &__avatar-icon-container {
      background-color: transparent;
    }
  }
}

.custom-tag-white {
  .andes-tag {
    color: $andes-white;
  }
}

.tag--andes-bg-secondary {
  background-color: $andes-bg-secondary;
}

.tag--bold .andes-tag {
  font-weight: 600;
}

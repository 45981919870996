.andes-card.full-media-card {
  position: relative;
  width: 100%;
  box-shadow: none !important;

  .full-media-card__link {
    border-radius: 18px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}

$andes-theme: mercadoplay;

@import '~@andes/common/index';
@import '~@andes/card/index';

@mixin aspect-ratio($width, $height) {
  padding-bottom: calc(100% * $height / $width);
  height: 0;
}

.andes-card.mediacard-vertical {
  position: relative;
  box-shadow: none;
  background-color: transparent;
  width: 100%;

  .mediacard__top-left-item-exclusive {
    width: 86px;
    .left-pill-exclusive {
      height: $andes-spacing-16;
    }
  }

  &.type--ranking {
    .poster-media-card__link {
      mask-image: url('/assets/images/mask-desktop.svg');
      mask-size: auto;
      mask-repeat: no-repeat;
      z-index: 1;
    }
  }

  .poster-media-card__link {
    transition: 200ms box-shadow ease-out;
    width: 100%;
    height: 100%;
    display: block;
    position: relative;
    box-shadow: 0 1px 2px 0 rgb(0 0 0 / 12%);
    overflow: hidden;

    &:hover {
      box-shadow: 0 1px 16px rgb(0 0 0 / 20%);
    }
  }

  &.size--M {
    height: 204px;
    width: 100%;
  }

  .poster-media-card {
    --radius: 6px;

    @include aspect-ratio(2, 3);

    position: relative;
    background-color: #c4c4c4;
    border-radius: var(--radius);
    box-shadow: 0 6px 16px 0 #0000001a;
    display: block;
    text-decoration: none;
  }

  .poster-media-card__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

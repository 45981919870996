$andes-theme: mercadoplay;
$display: 'mobile' !default;
@import '~@andes/common/index';

@import '../../styles/index';

.feed__item--feed-content-finished {
  grid-column: 1/-1;
  @if $display == $mobile {
    margin-bottom: pxToRem(16);
  }
  .feed-content__finished {
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      margin-top: pxToRem(6);
      margin-bottom: pxToRem(6);
      @if $display == $mobile {
        margin-left: pxToRem(17);
      }
      @if $display == $desktop {
        margin: 0;
      }
    }

    &-title {
      margin-left: pxToRem(16);
      @if $display == $desktop {
        margin-left: pxToRem(8);
      }
    }

    b {
      font-weight: 600;
    }
  }

  @if $display == $desktop {
    margin: pxToRem(43) 0 pxToRem(65) 0;
  }
}

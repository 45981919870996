.feed__item-rating {
  grid-column: 1 / -1;

  .andes-carousel-snapped {
    height: 206px;
    padding: 16px 0;
    margin-top: -16px;
  }

  .andes-carousel-snapped__container--content {
    margin: 0 !important;
    width: 100% !important;

    .andes-carousel-snapped--scroll-hidden {
      scrollbar-width: none;
      overflow-y: hidden;
    }

    .andes-carousel-snapped__control {
      z-index: 1 !important;
    }

    .andes-carousel-snapped__wrapper {
      height: 206px;
      gap: 16px;

      .andes-carousel-snapped__slide {
        margin-right: 0 !important;
        width: 136px !important;
        height: 204px;
        position: relative;
        transition: 200ms box-shadow ease-out;
      }

      .andes-carousel-snapped__slide svg {
        transition: filter 200ms ease-out;
      }

      .andes-carousel-snapped__slide:hover {
        svg {
          filter: drop-shadow(0 1px 6px rgb(0 0 0 / 40%));
        }
      }

      .andes-carousel-snapped__slide:last-child {
        margin-right: 0 !important;
      }

      svg {
        position: absolute;
        filter: drop-shadow(0 1px 2px rgb(0 0 0 / 12%));
        width: 100%;
        fill: #f5f5f5;
      }

      .carousel__item-rank {
        position: absolute;
        bottom: -4px;
        left: 0;
        height: 52px;
        width: 56px;
        pointer-events: none;
        color: #333;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 58px;
        font-weight: 800;
        letter-spacing: -1.71px;
      }
    }
  }
}

@media (width >= 1246px) {
  .feed__item-rating {
    .andes-carousel-snapped {
      width: calc(100% + 11px);
      padding-left: 11px;
      margin-left: -11px;
    }
  }
}

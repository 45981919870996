$andes-theme: mercadoplay;
$display: 'mobile' !default;
@import '~@andes/common/index';
@import '../../../styles';

.mediacard-compact {
  place-content: center center;
  display: flex;
  aspect-ratio: 16/9;
  position: relative;

  &__body {
    max-width: 100%;
    max-height: 100%;
    position: relative;
    border-radius: 0.375rem;

    &--image {
      background-color: $andes-gray-100;
      border-radius: 0.375rem;
      position: relative;
      display: flex;

      &::after {
        border-radius: 0.375rem;
        content: ' ';
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        background: linear-gradient(
          180deg,
          rgb(255 255 255 / 0%) 0%,
          rgb(255 255 255 / 0%) 70%,
          rgb(0 0 0 / 100%) 100%
        );
      }
    }

    &--with-centered-label {
      align-items: center;
      display: flex;
      height: 100%;
      justify-content: center;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 1;
      background-color: rgba($andes-black, 0.7);
      border-radius: 0.375rem;

      p {
        font-size: 0.875rem;
      }
    }

    img {
      border-radius: 0.375rem;
      position: relative;
      width: 100%;
      aspect-ratio: 16/9;
      object-position: center;
    }
  }

  &__text {
    &--bottom-left {
      position: absolute;
      bottom: 0;
      padding: 0.5rem;
      display: flex;
      align-items: center;

      .andes-typography--size-xs {
        color: $andes-white;
        font-size: 0.75rem;
        line-height: 0.93rem;
      }

      .andes-typography.andes-typography--size-bodyXS {
        color: $andes-white;
        font-weight: $font-weight-semibold;
        font-size: $font-size-12;
        line-height: 15px;
      }

      @if $display ==$mobile {
        padding: pxToRem(7) pxToRem(6);
      }
    }
  }

  &__icon-play {
    &--bottom-left {
      margin-right: 6px;
    }
  }

  &:hover {
    .mediacard-compact__icon-play {
      opacity: 0.7;
    }
  }

  &__top-right {
    width: 25px;
    height: 25px;
    display: flex;
    position: absolute;
    top: 0;
    right: 0;
    color: white;
    justify-content: center;
    align-items: center;
    background: radial-gradient(
      at top right,
      rgb(49 49 49 / 100%) -220%,
      rgb(49 49 49 / 0%) 80%
    );
    border-top-right-radius: pxToRem(6);
    z-index: 4;

    button {
      padding: 0;
      border: 0;
      width: 25px;
      height: 25px;
    }

    .andes-button--transparent svg[fill]:not([fill='none']) {
      fill: $andes-white !important;
    }

    .andes-button--transparent path[fill]:not([fill='none']) {
      fill: inherit !important;
    }

    .andes-button--transparent:focus path[fill]:not([fill='none']) {
      fill: inherit !important;
    }

    .andes-button--transparent:hover path[fill]:not([fill='none']) {
      fill: inherit !important;
    }

    button:hover {
      background-color: transparent;
    }
  }
}

.mediacard-compact__icon-play {
  width: pxToRem(32);
  opacity: 0;
  transition: opacity 150ms ease-out;
}

.mediacard-compact__container-icon {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 0;
  justify-content: center;
}

/**
  * NOTE: DON'T CHANGE HEIGHT IN ACTIVE STATUS
  **/
$andes-theme: mercadoplay;
@import '../../styles/index';

@import '~@andes/common/index';

.sticky-scroll__container {
  position: sticky;
  transform: translate3d(0, 0, 0);
  transition: all 0.3s;
  z-index: 2;
  background: $andes-bg-secondary;

  &:not(:has(.hub-filter__tag-selector)) &:not(:has(.remove-padding-bottom)) {
    padding-bottom: pxToRem(16);
  }

  .hub-filter__tag-selector {
    padding-bottom: pxToRem(16);
  }

  &.inactive-scroll {
    top: -20%;
    transform: translate3d(0, -100%, 0);
  }

  &.active-scroll {
    top: 0;
    margin: 0 (-$padding-x-wrapper);
    padding: 0 $padding-x-wrapper 0;
    transform: translate3d(0, 0, 0);

    .andes-tabs__border {
      margin: 0 (-$padding-x-wrapper);
      padding: 0 $padding-x-wrapper;
    }
  }

  .remove-padding-bottom {
    padding-bottom: 0;
  }
}

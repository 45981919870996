$andes-theme: mercadoplay;

@use '../../../container/ErrorContainer/ErrorContainer';
@use '../../../components/Banner/Banner';
@use '../../../components/ContentDetail/ContentDetail';
@use '../../../components/ContentDetailOverlay/desktop/ContentDetailOverlay';
@use '../../../components/SeasonSelector/desktop/SeasonSelector';
@use '../../../components/Player/Player';
@use '../../../components/Image/mobile/Image';
@use '../../../components/Snackbar/Snackbar';
@use '../../../components/SubHeader/desktop/style';
@use './components/RelatedContent/RelatedContent';

@import '~@andes/common/index';
@import '../../../styles';

$columns-layout-breakpoint: $lg-breakpoint;

.vcp-desktop {
  display: flex;
  flex-direction: column;
  flex: 1;
  min-width: 0;
  padding: 0 54px 32px;

  .sub-header-desktop {
    padding-bottom: 32px;
  }
}

.vcp-desktop__content-wrapper {
  flex: 1;
  min-width: 0;
}

.vcp-desktop__content {
  flex: 1;

  @media (min-width: $columns-layout-breakpoint) {
    display: flex;
  }
}

.vcp-desktop__primary-content {
  flex: 3;
  min-width: 0;
  display: flex;
  position: relative;

  .content-detail__button-tag {
    margin-top: 8px;
  }
}

.vcp-desktop__primary-content__float {
  align-self: flex-start;
  min-width: 0;
  width: 100%;
}

.vcp-desktop__secondary-content {
  flex: 2;
  min-width: 0;
  margin-top: 12px;

  @media (min-width: $columns-layout-breakpoint) {
    border-left: $andes-gray-100 solid 1px;
    margin-top: 0;
  }
}

.vcp-desktop__primary-content__head {
  aspect-ratio: 16/9;
}

.vcp-desktop__poster {
  display: flex;
  position: relative;

  img {
    width: 100%;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;

    @media (min-width: $columns-layout-breakpoint) {
      border-top-right-radius: 0;
    }
  }
}

.vcp-desktop__banner {
  padding: 16px;
  background-size: 100%;
}

.badgePill {
  position: absolute;
  bottom: 20px;
  left: 20px;

  .pill-tag {
    border-radius: 3px;
    padding: 2px;
  }
}

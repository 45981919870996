$andes-theme: mercadoplay;
$display: 'desktop' !default;

@use '../../../components/Feed/wrappers/MediaCardWrapper/MediaCardWrapper';

@use '../../../components/StickyScrollContainer/StickyScrollContainer';

@use '../../../container/ErrorContainer/ErrorContainer';
@use '../../../components/Snackbar/Snackbar';
@use '../../../components/Image/mobile/Image';
@use '../../../components/SubHeader/desktop/style';
@use '../../../components/FeedContentFinished/FeedContentFinished';
@import '~@andes/common/index';
@import '../../../styles';

.collections-desktop {
  flex: 1;
  height: auto;

  .collections-desktop__content {
    margin: 0 auto pxToRem(24) auto;
    display: flex;
    flex-direction: column;
    height: auto;
    width: auto;
  }

  &__feed {
    height: 100%;
    overflow: initial;

    .infinite-scroll-component {
      margin: 0 !important;
      padding: 0 !important;
      overflow: initial !important;
    }
  }

  @media (min-width: $md-breakpoint) {
    .sub-header-desktop {
      padding-bottom: 16px;

      .content-search {
        .nav-search {
          height: fit-content;
        }
      }
    }
  }
}

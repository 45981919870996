$andes-theme: mercadoplay;
@import '~@andes/common/index';

.left-pill-exclusive {
  height: 26px;

  .andes-badge__content {
    display: flex;
    align-items: center;
    gap: $andes-spacing-4;

    .pill-icon {
      position: relative !important;
      width: $andes-spacing-16;
      height: $andes-spacing-16;
    }
  }
}

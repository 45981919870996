$andes-theme: mercadoplay;

@import '~@andes/common/index';

.animated-button {
  position: relative;
  border: none;
  overflow: hidden;
  z-index: 1;
}

.animated-button.andes-button--loud:hover {
  background-color: $andes-blue-500;
}

.animated-button.animated-button--animated.andes-button--loud::before {
  transition: width 1s linear;
  width: var(--animated-button-width, 0%);
}

.animated-button.andes-button--loud::before {
  transition: none;
  transform: none;
  z-index: -1;
  padding: 0;
  margin: 0;
  width: 0;
}
